import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  getAuth,
  indexedDBLocalPersistence,
  setPersistence,
} from 'firebase/auth';
import { Database, onDisconnect, ref, set } from '@angular/fire/database';
import {
  ImageCacheService,
  LanguageService,
  LocalStorageService,
  RestartApplicationCommandAction,
} from '@freddy/common';
import { Asset, User as FreddyUser } from '@freddy/models';
import { NavigationEnd, Router } from '@angular/router';
import {
  AuthenticateUserAction,
  DisconnectPlayerAction,
  FetchGameAction,
  GameIsFinishedAction,
  JoinGameWrongCodeAction,
  ListenCommandsActions,
  ListenRouteActions,
  LoadGameAssetsAction,
  LoadGameAssetsSuccessAction,
  RedirectToAdminAction,
  RejoinGameAction,
  SelectLanguageAction,
  SetTenantAndOrganizationAction,
  SetUserDataAction,
} from '../../connect/actions/connect.actions';
import {
  catchError,
  EMPTY,
  filter,
  firstValueFrom,
  from,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { TeamRepository } from '../repository/team.repository';
import { InGameState } from './in-game.store';
import { AssetService } from '../services/asset.service';
import { CommandService } from '../services/command.service';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';
import { Auth, signInAnonymously, User } from '@angular/fire/auth';
import { Locale } from 'locale-enum';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { environment } from '../../../environments/environment';
import { MapPreloaderService } from '../services/map-preloader.service';
import { SoundTypeEnum } from '../../shared/models/Sound';
import { TranslateService } from '@ngx-translate/core';
import { SoundService } from '../services/sound.service';
import { HotToastService } from '@ngneat/hot-toast';
import {
  GoToCompanyLogoAction,
  GoToLanguagesSelectorAction,
} from '../../game/actions/game.actions';

export const CONNECT_STATE_TOKEN = new StateToken<ConnectStateModel>('connect');

export interface ConnectStateModel {
  loading: boolean;
  gameLoadingProgress: number;
  assets: Asset[];
  currentLang: Locale;
}

const defaultFormValue = {
  loading: false,
  gameLoadingProgress: 0,
  assets: [],
  currentLang: Locale.en,
};

@State<ConnectStateModel>({
  name: CONNECT_STATE_TOKEN,
  defaults: {
    ...defaultFormValue,
  },
})
@Injectable()
export class ConnectState {
  constructor(
    private readonly firestore: Firestore,
    private readonly imCache: ImageCacheService,
    private auth: Auth,
    private readonly router: Router,
    private readonly database: Database,
    private readonly commandService: CommandService,
    private readonly teamRepository: TeamRepository,
    private readonly assetService: AssetService,
    private readonly store: Store,
    private readonly languageService: LanguageService,
    private readonly localStorageService: LocalStorageService,
    private readonly mapPreloaderService: MapPreloaderService,
    private readonly translateService: TranslateService,
    private readonly soundService: SoundService,
    private toast: HotToastService,
    private functions: Functions,
  ) {}

  @Selector([ConnectState])
  static loading(state: ConnectStateModel): boolean {
    return state.loading;
  }

  @Selector([ConnectState])
  static currentLang(state: ConnectStateModel): Locale {
    return state.currentLang;
  }

  @Selector([ConnectState])
  static gameLoadingProgress(state: ConnectStateModel): number {
    return state.gameLoadingProgress;
  }

  @Selector([ConnectState])
  static locale(state: ConnectStateModel): Locale {
    return state.currentLang;
  }

  static asset(assetUid: string) {
    return createSelector([ConnectState], (state: ConnectStateModel) => {
      return state.assets.find((asset) => asset.uid === assetUid);
    });
  }

  static assetByTypeAndUid(assetUids: string[], type: string) {
    return createSelector([ConnectState], (state: ConnectStateModel) => {
      return state.assets.filter(
        (asset) =>
          assetUids.includes(asset.uid) && asset.metadata?.['type'] === type,
      );
    });
  }

  static assetsByType(assetType: string) {
    return createSelector([ConnectState], (state: ConnectStateModel) => {
      return state.assets.filter(
        (asset) => asset?.metadata?.['type'] === assetType,
      );
    });
  }

  @Action(LoadGameAssetsSuccessAction)
  loadGameAssetsSuccessAction() {
    this.store.dispatch(new GoToLanguagesSelectorAction());
  }

  @Action(GoToCompanyLogoAction)
  goToCompanyLogoAction() {
    const game = this.store.selectSnapshot(InGameState.game);
    return from(this.router.navigate(['game', game?.code, 'intro']));
  }

  @Action(GoToLanguagesSelectorAction)
  goToLanguagesSelectorAction() {
    const game = this.store.selectSnapshot(InGameState.game);
    return from(this.router.navigate(['game', game?.code]));
  }

  @Action(GameIsFinishedAction)
  async gameIsFinishedAction() {
    this.toast.error(
      this.translateService.instant('game.inGameStore.gameIsFinishedAction'),
    );
    this.router.navigate(['join']);
  }

  @Action(JoinGameWrongCodeAction)
  join() {
    this.soundService.playSound(SoundTypeEnum.MINOR_ERROR);
    this.toast.error(
      this.translateService.instant('game.inGameStore.joinGameWrongCodeAction'),
    );
  }

  @Action(SelectLanguageAction)
  selectLanguageAction(
    ctx: StateContext<ConnectStateModel>,
    action: SelectLanguageAction,
  ) {
    const lang = action.lang.split('-')[0].toLowerCase();
    this.languageService.setLanguage(lang);
    ctx.patchState({
      currentLang: action.lang,
    });
  }

  @Action(ListenCommandsActions, { cancelUncompleted: true })
  listenCommandsActions(
    ctx: StateContext<ConnectStateModel>,
    action: ListenCommandsActions,
  ) {
    const teamUid = action.team.uid;
    console.info('Listening commands for team', teamUid);
    return this.commandService.listenForCommands(teamUid);
  }

  @Action(ListenRouteActions, { cancelUncompleted: true })
  listenRouteActions() {
    // TODO: we should use realtime database
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      switchMap((event) => {
        const teamUid = this.store.selectSnapshot(InGameState.myTeam)?.uid;

        if (!teamUid) {
          console.log('No team UID available, skipping update');
          return of(null);
        }

        const currentState = this.determineCurrentState(
          event.urlAfterRedirects,
        );

        console.trace(`Updating team (${teamUid}) state:`, currentState);

        return from(
          this.teamRepository.update({
            uid: teamUid,
            online: true,
            currentPath: event.urlAfterRedirects,
            currentState,
          }),
        ).pipe(tap(() => console.log('Team update completed')));
      }),
    );
  }

  @Action(RedirectToAdminAction)
  redirectToAdminAction(
    ctx: StateContext<ConnectStateModel>,
    action: RedirectToAdminAction,
  ) {
    const callable = httpsCallable<
      { gameCode: string },
      { customToken: string }
    >(this.functions, 'createevaluatortoken');
    return fromPromise(
      callable({
        gameCode: action.game.code,
      }),
    ).pipe(
      tap(async (response) => {
        const tenantId = (await this.getConfig(action.organizationSlug))
          ?.tenantId;

        const baseUrl = `${environment.admin.url}/game/control/${action.game.uid}/`;
        const tokenParam = `token=${response.data.customToken}`;
        const tenantParam = tenantId
          ? `&tenantId=${tenantId}&organization=${action.organizationSlug}`
          : '';

        const fullUrl = `${baseUrl}?${tokenParam}${tenantParam}`;
        console.log('Redirecting to', fullUrl);
        window.location.href = fullUrl;
      }),
    );
  }

  @Action(LoadGameAssetsAction)
  async loadGameAction(
    ctx: StateContext<ConnectStateModel>,
    action: LoadGameAssetsAction,
  ) {
    const assets = await firstValueFrom(
      this.assetService.getAssets(action.game),
    );

    ctx.patchState({
      assets,
    });

    const cacheProgress = this.imCache.cacheImages([
      ...assets.map((c) => c.path),
    ]);
    await this.mapPreloaderService.preloadTiles(
      action.game.scenario.location.center,
    );

    cacheProgress.subscribe({
      next: (progress) => {
        ctx.patchState({
          gameLoadingProgress: progress,
        });
      },
      error: (err: any) => {
        console.error(err);
      },
      complete: () => {
        setTimeout(() => {
          ctx.dispatch(new LoadGameAssetsSuccessAction());
        }, 1000);
      },
    });
    return await this.router.navigate([`/loading`]);
  }
  @Action(RejoinGameAction)
  async rejoinGameAction() {
    const gameCode = await this.localStorageService.get('gameCode');
    if (gameCode) {
      return this.store.dispatch(new FetchGameAction(gameCode));
    }
    return;
  }

  @Action(DisconnectPlayerAction)
  disconnectPlayerAction() {
    this.localStorageService.remove('gameCode');
    return from(getAuth().signOut());
  }

  @Action(AuthenticateUserAction)
  setTenantAndAuthenticateUser(
    ctx: StateContext<ConnectStateModel>,
    action: AuthenticateUserAction,
  ): Observable<void> {
    return from(this.getConfig(action.organizationSlug)).pipe(
      switchMap((config) => {
        getAuth().tenantId = config?.tenantId ?? null;
        return from(setPersistence(this.auth, indexedDBLocalPersistence));
      }),
      switchMap(() =>
        ctx.dispatch(
          new SetTenantAndOrganizationAction({
            tenantId: getAuth().tenantId,
            organizationSlug: action.organizationSlug,
          }),
        ),
      ),
      switchMap(() => from(signInAnonymously(this.auth))),
      switchMap((userCredential) => {
        if (userCredential.user) {
          return from(this.handleUserConnection(ctx, userCredential.user));
        }
        return EMPTY;
      }),
      catchError((error) => {
        console.error('Error during authentication process:', error);
        // You might want to dispatch an error action here
        // ctx.dispatch(new AuthenticationErrorAction(error));
        return throwError(() => new Error('Authentication failed'));
      }),
      map(() => void 0), // Ensures the Observable completes with void
    );
  }

  getPublicConfigPath(organizationSlug: string): string {
    return `organizations/${organizationSlug}/public/config`;
  }

  @Action(RestartApplicationCommandAction)
  restartApplicationCommandAction() {
    location.reload();
  }

  private determineCurrentState(url: string): 'AVAILABLE' | 'UNKNOWN' {
    return url.includes('/map') || url.endsWith('/challenges')
      ? 'AVAILABLE'
      : 'UNKNOWN';
  }

  private async getConfig(
    organizationSlug: string,
  ): Promise<{ tenantId: string } | undefined> {
    const docRef = doc(
      this.firestore,
      this.getPublicConfigPath(organizationSlug),
    );
    const docSnapshot = await getDoc(docRef);
    return docSnapshot.data() as any;
  }

  private async handleUserConnection(
    ctx: StateContext<ConnectStateModel>,
    user: User,
  ): Promise<void> {
    const realTimeRef = ref(this.database, `/status/${user.uid}`);
    await onDisconnect(realTimeRef).set({ state: 'offline' });
    await set(realTimeRef, { state: 'online' });

    const token = await user.getIdTokenResult();
    const organization = token?.claims['organization'] as string;

    const userData: FreddyUser = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      organization: organization,
      lastSeen: new Date().getTime(),
      createdAt: new Date(),
    };

    ctx.dispatch(new SetUserDataAction(userData));
  }
}
